




























import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Breadcrum extends Vue {
  @Prop({ default: '/' }) private separator!: string;
  @Prop({ default: [] }) private list!: any[];
}
