
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { FileDownload } from '@/http/api/FileApi';


@Component
export default class FileEcho extends Vue {
  @Prop() private fileList!: any[];
  private unload(path: string, name: string) {
    FileDownload(path, name);
  }
}
