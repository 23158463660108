





































































































import { Component, Vue } from 'vue-property-decorator';
import { IbreadcrumbItem } from '@/assets/model/interface';
import { GetCmsArticleList, GetCmsArticleDeteil } from '@/http/api/ArticleApi';
import TableRow from '@c/table/TableRow.vue';
import TableItem from '@c/table/TableItem.vue';
import FileEcho from '@c/upload/FileEcho.vue';
import Breadcrum from '@c/breadcrum/Breadcrum.vue';

@Component({
  components: {
    TableRow,
    TableItem,
    FileEcho,
    Breadcrum,
  },
})
export default class ContributeRecord extends Vue {
  private loading: boolean = false;
  private breadcrumbList: IbreadcrumbItem[] = [
    { name: '会员服务', path: '/serve' },
    { name: '会员简介', path: '/serve/memberintroduction' },
    { name: '投稿记录', path: '' },
  ];
  private list: any[] = [];
  private totalPage: number = 0; // 总页数
  private totalCount: number = 0; // 总条数
  private pageNo: number = 1; // 当前页数
  private pageSize: number = 10; // 每页几条
  private detailDialog: any = {
    show: false,
    data: '',
  };

  private created() {
    this.getList();
  }

  // 查看文章详情
  private itemClick(id: string): void {
    this.loading = true;
    GetCmsArticleDeteil({ id }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        this.detailDialog.data = res.result;
        this.detailDialog.show = true;

      }
    });
  }
  private clearSubmit() {
    this.detailDialog.show = false;
  }
  // 获取文章数据列表
  private getList(): void {
    this.loading = true;
    GetCmsArticleList({
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        const data = res.result;
        this.totalCount = data.total;
        this.totalPage = data.pages;
        this.pageNo = data.current;
        this.list = data.records;
      }
    });
  }
  private handleSizeChange(val: number): void {
    this.pageNo = 1;
    this.pageSize = val;
    this.getList();
  }
  private handleCurrentChange(val: number): void {
    this.pageNo = val;
    this.getList();
  }
  private listSearch(): void {
    this.pageNo = 1;
    this.getList();
  }
}
